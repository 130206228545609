<template>
  <div class="container">
    <div class="row">
      <div class="col">
        @UCSP
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
    }
  },

  created() {
  },

  methods: {

  }
}
</script>

<style scoped>
</style>
