<template>
  <div class="container">
    <div class="row">
      <div class="col" v-if="validation.status">
        <div class="bg-light p-5 rounded-lg m-3">
          <h4><b>Alumno:</b> {{validation.student.last_name}}, {{validation.student.first_name}} </h4>
          <p>Codigo Alumno UCSP: {{ validation.student.code }}</p>
          <p>Universidad de Origen: {{ validation.university_origin }}</p>
          <p>Carrera de Origen: {{ validation.program_origin }}</p>
          <hr class="my-4">
          <p>Carrera destino: {{ validation.course.program }}</p>
          <p>Syllabus: {{ validation.course.syllabus }}</p>
          <p>Nombre del curso: {{ validation.course.name }}</p>
          <p>Codigo del curso: {{ validation.course.code }}</p>
          <p>Semestre del curso: {{ validation.course.semester }}</p>
          <p>Creditos del curso: {{ validation.course.credits }}</p>
          <hr class="my-4">
          <p>Listado de cursos de convalidación:</p>
        </div>

        <table class="table table-bordered">
          <tr>
            <th>Curso Origen</th>
            <th>Nota</th>
            <th>Creditos</th>
            <th>Silabus</th>
            <th>Recomendaciones</th>
          </tr>

          <tr v-for="conv in validation.origin_course" :key="conv.index">
            <td>{{ conv.name }}</td>
            <td>{{ conv.note }}</td>
            <td>{{ conv.credits }}</td>
            <td>
              <a :href="'/pdf/' + token + '/' + conv.index" target="_blank">Ver PDF</a>
            </td>
            <td>
              <div v-if="suggest_end">
                <div v-if="suggest[conv.index].status">
                  <span class="badge bg-primary">Aceptada con anterioridad</span> -
                  <span class="badge bg-primary">{{ suggest[conv.index].data.count }} Aceptados</span> -
                  <span class="badge bg-primary">Aceptado por ultima vez en {{ suggest[conv.index].data.period }}</span>
                </div>
                <span v-else class="badge bg-warning">No existe convalidacion similar</span>
              </div>
            </td>
          </tr>

        </table>

        <h4>Notas de convalidación - (Maximo {{ maxCount }} caracteres)</h4>

        <textarea v-model="note" v-on:keyup="countdown">

        </textarea>

        <p class='text-right text-small' v-bind:class="{'text-danger': hasError }">{{remainingCount}}</p>

        <div v-if="!hasError">
          <button class="btn btn-primary" v-on:click="accept()" >Aceptar</button><br/>
          <button class="btn btn-danger" v-on:click="denied()" >Rechazar</button>
        </div>
      </div>

      <div class="col" v-else>
        Convalidacion resuelta
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Convalidation',
  props: {
    msg: String
  },
  data() {
    return {
      validation: [],
      note: '',
      status: '',
      token: this.$route.params.token,
      api: 'https://convalida.ucsp.edu.pe/api/',
      suggest: [],
      suggest_end: false,
      maxCount: 1000,
      remainingCount: 1000,
      hasError: false
    }
  },

  created() {
    fetch(this.api + this.token + '/view')
        .then(response => response.json())
        .then(json => {
          this.validation = json
        })

    fetch(this.api + 'query/' + this.token)
        .then(response => response.json())
        .then(json => {
          this.suggest = json
          this.suggest_end = true
        })

  },

  methods: {
    countdown: function() {
      this.remainingCount = this.maxCount - this.note.length;
      this.hasError = this.remainingCount < 0;
    },

    accept: function () {
      var data = new FormData()
      data.append('note', this.note)

      fetch(this.api + 'accept/' + this.token, {
        method: 'POST',
        body: data,
      })
          .then(response => response.json())
          .then(json => {
            this.status = json
            this.$router.go()
          })
    },
    denied: function () {
      var data = new FormData()
      data.append('note', this.note)

      fetch(this.api + 'decline/' + this.token, {
        method: 'POST',
        body: data,
      })
          .then(response => response.json())
          .then(json => {
            this.status = json
            this.$router.go()
          })
    },
  }
}
</script>

<style scoped>
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}
</style>
